<template>
      <section id="feature">
        <div class="title-text">
          <p>KENMERKEN</p>
          <h1>Waarom kiezen voor ons</h1>
        </div>
        <div class="feature-box">
            <div class="features">
              <h1>Ervaren Personeel</h1>
              <div class="features-desc">
                <div class="feature-icon">
                  <i class="fa-solid fa-shield-halved"></i>
                </div>
                <div class="feature-text">
                  <p>Onze kappers hebben al meer dan 10 jaar werkervaring en doen hun werk nog altijd met veel passie en plezier.</p>
                </div>
              </div>
              <h1>Consistente kwaliteit</h1>
              <div class="features-desc">
                <div class="feature-icon">
                  <i class="fa-regular fa-square-check"></i>
                </div>
                <div class="feature-text">
                  <p>Klanten kunnen altijd rekenen op consistente en hoogwaardige service, ongeacht welke kapper ze kiezen binnen ons team.</p>
                </div>
              </div>
              <h1>Betaalbare prijzen</h1>
              <div class="features-desc">
                <div class="feature-icon">
                  <i class="fa-solid fa-euro-sign"></i>
                </div>
                <div class="feature-text">
                  <p>Aziz Style is met afstand de goedkoopste kapper van Spijkenisse.</p>
                </div>
              </div>
            </div>
            <div class="features-img">
              <div class="image-container">
                <img src="../assets/images/aziz-actie-foto.jpg" alt="Aziz Khalefah van Aziz Style knipt een klant">
                <div class="overlay-action-img"></div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WhyChooseUs"
}
</script>

<style scoped>
#feature {
  width: 100%;
  padding: 70px 0;
}

.feature-box {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}
.features {
  flex-basis: 50%;
}
.features-img {
  flex-basis: 50%;
  margin: auto;
}

.features-img .image-container{
  /* background-color: pink; */
  width: 70%;
  position: relative;
  margin: auto;
}
.features-img .image-container .overlay-action-img {
  background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 10px;
  background: linear-gradient(transparent 0%, #009688 100%);
  opacity: 1;
}


.features-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.features h1 {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 22px;
  color: #009688;
}
.features-desc {
  display: flex;
  align-items: center;
  margin-bottom: 40px;;
}
.feature-icon .fa-solid {
  width: 50px;
  height: 50px;
  font-size: 30px;
  line-height: 50px;
  border-radius: 8px;
  color: #009688;
  border: 1px solid #009688;
}
.feature-icon .fa-regular {
  width: 50px;
  height: 50px;
  font-size: 30px;
  line-height: 50px;
  border-radius: 8px;
  color: #009688;
  border: 1px solid #009688;
}
.feature-text p{
  padding: 0 20px;
  text-align: initial;
}
@media screen and (max-width: 978px) {
  #feature {
    padding: 60px 0;
  }
  .features {
    flex-basis: 100%;
  }
  .features h1 {
    font-size: 16px;
  }
  .feature-text p{
    font-size: 14px;
  }
  .features-img {
    flex-basis: 100%;
    margin: auto;
  }
  .features-img .image-container{
    /* background-color: pink; */
    width: 100%;
  }
  .features-img img {
    width: 100%;
  }
}

</style>