<template>
      <section id="testimonial">
       <div class="title-text">
          <p>ERVARINGEN</p>
          <h1>Wat onze klanten zeggen</h1>
        </div>
        <div class="testimonial-row">
          <a class="testimonial-col" href="https://www.google.nl/search?sa=X&sca_esv=e1e2d42c7b33852d&biw=1536&bih=730&tbm=lcl&q=reviews%20voor%20aziz%20style%20spijkenisse&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIytjAxszQ2MzU1NjUxsTS0MDDewMj4ilG5KLUsM7W8WKEsP79IIbEqs0qhuKQyJ1WhuCAzKzs1L7O4OHURKzGqAFqLZWplAAAA&rldimm=384693655354491803&hl=nl-NL&ved=0CBEQ5foLahcKEwjIovzNn62GAxUAAAAAHQAAAAAQBA#lkt=LocalPoiReviews&arid=ChdDSUhNMG9nS0VJQ0FnSURqMExTMzBBRRAB" target="blank">
            <div class="user">
              <!-- <img src="../assets/images/img-1.jpg" alt=""> -->
              <div class="user-info">         
                <h4>Ali Al-Saady<!--<i class="fa-brands fa-twitter"></i> --></h4>
                <!-- <small>@kennorman</small> -->
                <div class="stars">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>        
                </div>
              </div>
            </div>
            <p>Aziz is al jaren mijn vaste kapper en ik ben nog altijd super tevreden over hem. Hij gaat zorgvuldig te werk en probeert altijd een mooi resultaat neer te zetten. Ik zie aan hem dat die zijn werk met passie en plezier doet. Hij stelt me nooit teleur en ik zou hem aan iedereen aanraden!</p> 
          </a>
          <a class="testimonial-col" href="https://www.google.nl/search?sa=X&sca_esv=e1e2d42c7b33852d&biw=1536&bih=730&tbm=lcl&q=reviews%20voor%20aziz%20style%20spijkenisse&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIytjAxszQ2MzU1NjUxsTS0MDDewMj4ilG5KLUsM7W8WKEsP79IIbEqs0qhuKQyJ1WhuCAzKzs1L7O4OHURKzGqAFqLZWplAAAA&rldimm=384693655354491803&hl=nl-NL&ved=0CBEQ5foLahcKEwjIovzNn62GAxUAAAAAHQAAAAAQBA#lkt=LocalPoiReviews&arid=ChdDSUhNMG9nS0VJQ0FnSURqMExTMzBBRRAB" target="blank">
            <div class="user">
              <!-- <img src="../assets/images/img-3.jpg" alt=""> -->
              <div class="user-info">         
                <h4>Lucien Poldervaart<!--<i class="fa-brands fa-twitter"></i> --></h4>
                <!-- <small>@rickydanial</small> -->
                <div class="stars">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>        
                </div>
              </div>
            </div>
            <p>Aziz Style is een uitstekende kapper die vakmanschap en precisie combineert met een vriendelijke en professionele service. Zijn vaardigheid en ervaring komen duidelijk naar voren in zijn werk, en hij luistert goed naar de wensen van zijn klanten om ervoor te zorgen dat ze tevreden zijn met het eindresultaat. Ik zou Aziz Style ten zeerste aanbevelen aan iedereen die op zoek is naar een betrouwbare en getalenteerde kapper.</p> 
          </a>
          <a class="testimonial-col" href="https://www.google.nl/search?sa=X&sca_esv=e1e2d42c7b33852d&biw=1536&bih=730&tbm=lcl&q=reviews%20voor%20aziz%20style%20spijkenisse&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIytjAxszQ2MzU1NjUxsTS0MDDewMj4ilG5KLUsM7W8WKEsP79IIbEqs0qhuKQyJ1WhuCAzKzs1L7O4OHURKzGqAFqLZWplAAAA&rldimm=384693655354491803&hl=nl-NL&ved=0CBEQ5foLahcKEwjIovzNn62GAxUAAAAAHQAAAAAQBA#lkt=LocalPoiReviews&arid=ChdDSUhNMG9nS0VJQ0FnSURqMExTMzBBRRAB" target="blank">
            <div class="user">
              <!-- <img src="../assets/images/img-2.jpg" alt=""> -->
              <div class="user-info">         
                <h4>Shane Broeder <!--<i class="fa-brands fa-twitter"></i> --></h4>
                <!-- <small>@liarakarian</small> -->
                <div class="stars">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>        
                </div>
              </div>
            </div>
            <p>Aziz style is wat mij betreft de beste kapper in de regio, voor al je haar wensen kan je bij hem terecht en hij maakt er een mooi kunstwerk van. Mensen vragen vaak waar ik me haar knip en ik verwijs ze dan ook graag door naar Aziz. Iedereen is altijd heel blij met hem. 5 sterren waardig.</p> 
          </a>
        </div>
    </section>
</template>

<script>
export default {
    name: "Testimonial"
}
</script>

<style>
#testimonial {
  width: 100%;
  padding: 70px 0;
}
.testimonial-row {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.testimonial-col {
  flex-basis: 28%;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 10px 20px 3px #00968814;
  text-decoration: none;
  /* cursor: pointer; */
  transition: transform 0.5s;
}
.testimonial-col p {
  font-size: 14px;
}
.testimonial-col h4 {
  color: #000;
}
.user {
  display: flex;
  align-items: center;
  margin: 5px;
}
.user img {
  width: 40px;
  margin-right: 20px;
  border-radius: 3px;
}
.user-info i {
  /* margin-left: 10px; */
  font-size: 16px;
  color: #FFDF0C; 
  /* background-color: pink; */
}
.user-info small {
  color: #009688;
}
.testimonial-col:hover {
  transform: translateY(-7px);
  cursor: pointer;
}
@media screen and (max-width: 978px) {
  #testimonial {
    padding: 60px 0;
  }
  .testimonial-col {
    flex-basis: 100%;
  }
}
</style>