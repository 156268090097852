<template>
      <!-- Footer -->
      <section id="footer">
      <img src="../assets/images/footer-img.png" class="footer-img" alt="Bezoek Aziz Style in Spijkenisse vandaag nog"/>
        <div class="title-text">
          <p>CONTACT</p>
          <h1>Bezoek ons vandaag nog</h1>
        </div>
        <div class="footer-row">
          <div class="footer-left">
            <h1>Openingstijden</h1>
            <p><i class="fa-solid fa-clock"></i>ma 12:00 - 18:00</p>
            <p><i class="fa-solid fa-clock"></i>di t/m za 10:00 - 19:00</p>
            <p><i class="fa-solid fa-clock"></i>Elke donderdag 10:00 - 21:00</p>
          </div>
          <div class="footer-right">
            <h1>Contactgegevens</h1>
            <!-- <p>#30 abc Colony, xyz city IN<i class="fa-solid fa-location-dot"></i></p> -->
            <div class="address"><p>Uitstraat 5<br>3201 EN Spijkenisse</p><i class="fa-solid fa-location-dot"></i></div>
            <p>info@aziz-style.nl<i class="fa-solid fa-envelope"></i></p>
            <p>0685467120<i class="fa-solid fa-phone"></i></p>
          </div>
        </div>
        <div class="social-links">
          <!-- <i class="fa-brands fa-facebook"></i> -->
          <a href="https://www.instagram.com/azezkalefa" target="_blank"><i class="fa-brands fa-instagram"></i></a>
          <!-- <i class="fa-brands fa-twitter"></i>
          <i class="fa-brands fa-youtube"></i> -->
          <p>&copy; {{ new Date().getFullYear() }} Aziz Style</p>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
/* Footer */
#footer {
  padding: 100px 0 20px;
  background: #EFEFEF;
  position: relative;
}
.footer-row {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-left, .footer-right {
  flex-basis: 45%;
  padding: 10px;
  margin-bottom: 20px;
}
.footer-right {
  text-align: right;
}
.footer-right .address {
  /* background-color: pink; */
  display: flex;
  justify-content: end;
}
.footer-right .address p {
  position: relative;
  left: 5px;
}
.fa-location-dot {
  padding: 0px 5px;
  position: relative;
  top: -1px !important;
}
.footer-row h1 {
  margin: 10px 0;
}
.footer-row p {
  /* line-height: 25px; */
}
.footer-left i, .footer-right i{
  font-size: 16px;
  color: #009688;
  margin: 10px;
}
.footer-right i{
  position: relative;
  top: 3px;
}
.footer-img {
  max-width: 370px;
  opacity: 0.1;
  position: absolute;
  top: 0%;
  left: 0; 
  right: 0;  
  margin-left: auto; 
  margin-right: auto; 
}
.social-links {
  text-align: center;
}
.social-links i {
  height: 40px;
  width: 40px;
  font-size: 20px;
  line-height: 40px;
  border: 1px solid #009688;
  margin: 40px 5px 0;
  color: #009688;
  cursor: pointer;
  transition: 0.5s;
}
.social-links i:hover {
  background: #009688;
  color: #FFF;
  transform: translateY(-7px);
}
.social-links p {
  font-size: 12px;
  margin-top: 20px;
}
@media screen and (max-width: 978px) {
  #footer {
    padding: 60px 0px 20px;
  }
  .footer-left, .footer-right {
    flex-basis: 100%;
    font-size: 14px;
  }
  .footer-left h1, .footer-right h1 {
    font-size: 16px;
  }
}
</style>