<template>
  <div>
     <div class="navbar">
        <div class="navbar-container">
            <router-link to="/" class="logo">Aziz <span class="style-text">Style</span></router-link>
            <ul class="menu-items">
            <router-link to="/"><li>HOME</li></router-link>
            <router-link to="/over-ons"><li>OVER ONS</li></router-link>
            <router-link to="/het-team"><li>HET TEAM</li></router-link>
            <router-link to="/galerij"><li>GALERIJ</li></router-link>
            <router-link to="/prijslijst"><li>PRIJSLIJST</li></router-link>
            <router-link to="/contact"><li>CONTACT</li></router-link>
            </ul>
            <router-link to="/contact"><div class="appointment-btn">Maak een afspraak</div></router-link>
        </div>
     </div>
     <div class="navbar-mobile">
            <router-link to="/" class="logo">Aziz <span class="style-text">Style</span></router-link>
            <div id="menuBtn" @click="MenuBtnClicked()">
                <i class="fa-solid fa-bars"></i>     
            </div>
      </div>
      <div id="sideNav" ref="sideNavRef" v-click-outside-element="CloseSideNavOnClickOutside">
            <nav>
                <i class="fa-solid fa-xmark" @click="CloseSideNav()"></i>
                <ul>
                  <router-link to="/" @click="CloseSideNav()"><li>HOME</li></router-link>
                  <router-link to="/over-ons" @click="CloseSideNav()"><li>OVER ONS</li></router-link>
                  <router-link to="/het-team" @click="CloseSideNav()"><li>HET TEAM</li></router-link>
                  <router-link to="/galerij" @click="CloseSideNav()"><li>GALERIJ</li></router-link>
                  <router-link to="/prijslijst" @click="CloseSideNav()"><li>PRIJSLIJST</li></router-link>
                  <router-link to="/contact" @click="CloseSideNav()"><li>CONTACT</li></router-link>
                </ul>
            </nav>
        </div>
  </div>
</template>

<script>
export default {
    name: "NavBar",
    data(){
      return {
        clickedOnMenuBtn: false
      }
    },
    methods: {
      MenuBtnClicked(){
      this.clickedOnMenuBtn = true;
      if(this.$refs.sideNavRef.style.right == "-250px") {
        this.$refs.sideNavRef.style.right = "0px";
      }
      else {
        this.$refs.sideNavRef.style.right = "-250px";
      }
    },
    CloseSideNav(){
        this.$refs.sideNavRef.style.right = "-250px";
    },
    CloseSideNavOnClickOutside(){
      if(this.$refs.sideNavRef.style.right == "0px" && this.clickedOnMenuBtn == false) {
        this.$refs.sideNavRef.style.right = "-250px";
      }
      this.clickedOnMenuBtn = false;
    }
  },
  mounted() {
  this.$refs.sideNavRef.style.right = "-250px"
  }
}
</script>

<style>
.navbar-mobile {
  display: none;
}
.navbar {
  display: flex;
  justify-content: center;
}
.navbar-container {
  /* background-color: pink; */
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0px; 
}
.navbar-container a{
  text-decoration: none;
}
.navbar .menu-items {
  /* background-color: red; */
  display: flex;
  list-style: none;
  column-gap: 20px;
}
.menu-items a {
  text-decoration: none;
  color: #000;
}
.menu-items li {
  cursor: pointer;
}
.menu-items li::after {
  content: "";
  width: 0%;
  height: 2px;
  background: #009688;
  display: block;
  margin: auto;
  transition: 0.5s;
}
.menu-items li:hover::after {
  width: 100%;
}
.logo {
  font-size: 26px;
  font-family: "Kaushan Script", cursive;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.logo .style-text {
  color: #009688  ;
  font-family: "Kaushan Script", cursive;
}
.appointment-btn {
  background-color: #009688;
  color: #FFF;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

#sideNav {
  width: 250px;
  /* height: 100vh; */
  min-height: -webkit-fill-available;
  position: fixed;
  right: -250px;
  top: 0;
  background: #009688;
  z-index: 2;
  transition: 0.5s;
}
.fa-xmark {
  color: #FFF;
  font-size: 22px;
  position: absolute;
  right: 35px;
  top: 22px;
  cursor: pointer;
}
nav ul a {
  text-decoration: none; 
  color: #FFF;
  display: block;
  margin: 50px 20px;
  width: fit-content;
}
nav ul a li {
  list-style: none;
  /* background-color: green; */

}
#menuBtn {
  width: 35px;
  height: 35px;
  background-color: #009688;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
}
.fa-bars {
  color: #FFF;
  font-size: 18px;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
}

/* hide logo for better view */
@media screen and (max-width: 1010px) {
  .logo {
    display: none;
  }
}

@media screen and (max-width: 978px) {
  .logo {
    display: block;
  }
  .navbar-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    padding: 14px 0px; 
    /* background-color: pink; */
  }
  #menuBtn {
    display: block;
  }
  .navbar {
    display: none;
  }
  .logo {
    font-size: 20px;
  }
}

/* For landscape only */
@media only screen and (max-width: 978px) and (orientation: landscape) {
  #sideNav nav {
      height: 100vh;
      overflow-y: scroll;
  }
}
</style>