<template>
    <section id="service">
        <div class="title-text">
          <p>DIENSTEN</p>
          <h1>Wij leveren kwaliteit</h1>
        </div>
        <div class="service-box">
          <div class="single-service">
            <img src="../assets/images/haircut-img.jpg" alt="Haren knippen">
            <div class="overlay"></div>
            <div class="service-desc">
              <h3>Knippen</h3>
              <hr>
              <p>Of u nu kiest voor een subtiele trim of een gedurfde nieuwe look, wij staan klaar om uw droomkapsel tot leven te brengen.</p>
            </div>
          </div>
          <div class="single-service">
            <img src="../assets/images/haircut-img-beard.jpg" alt="Baard trimmen">
            <div class="overlay"></div>
            <div class="service-desc">
              <h3>Baard</h3>
              <hr>
              <p>Laat ons ervaren team van barbiers uw baard transformeren en ontdek de kunst van baardverzorging op zijn best.</p>
            </div>
          </div>
          <div class="single-service">
            <img src="../assets/images/pic-2.jpg" alt="Contouren bijwerken">
            <div class="overlay"></div>
            <div class="service-desc">
              <h3>Contouren</h3>
              <hr>
              <p>Ontdek hoe onze deskundige stylisten jouw contouren kunnen perfectioneren voor een onberispelijke uitstraling.</p>
            </div>
          </div>
          <div class="single-service">
            <img src="../assets/images/pic-4.jpg" alt="Haren wassen">
            <div class="overlay"></div>
            <div class="service-desc">
              <h3>Wassen</h3>
              <hr>
              <p>Laat je verwennen met een onvergetelijke waservaring die je zintuigen prikkelt en je haar revitaliseert.</p>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {
name: "ServiceSection"
}
</script>

<style scoped>
#service {
  width: 100%;
  padding: 70px 0;
  background: #EFEFEF;
}
.service-box {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}
.single-service {
  flex-basis: 48%;
  text-align: center;
  border-radius: 7px;
  margin-bottom: 20px;
  color: #FFF;
  position: relative;
  cursor: default;
}
.single-service img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
.overlay {
  width: 100%;
  /* height: calc(100% - 5px); */
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 7px;
  /* cursor: pointer; */
  background: linear-gradient(rgba(0,0,0,0.5), #009688);
  opacity: 0;
  transition: 1s;
}
.single-service:hover .overlay{
  opacity: 1;
}
.service-desc {
  width: 80%;
  position: absolute;
  bottom: 0%;  /* position the bottom edge of the element at the bottom of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 50%);
  opacity: 0;
  transition: 1s;
}
hr {
  background: #FFF;
  height: 2px;
  border: 0;
  margin: 15px auto;
  width: 60%;
}
.service-desc p {
  all: unset;
  font-size: 14px;
}
.single-service:hover .service-desc {
  bottom: 50%;
  opacity: 1;
}
@media screen and (max-width: 978px) {
  #service {
    padding: 60px 0;
  }
  .single-service {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  .service-desc {
    font-size: 13px;
  }
  .service-desc p {
    font-size: 13px;
  }
  hr {
    margin: 5px auto;
  }
}
</style>