<template>
  <div>
    <section id="banner">
      <div class="banner-text-container">
          <h1 class="banner-title">{{ this.$store.state.titleBanner }}</h1>
          <h3 class="banner-subtitle">{{ this.$store.state.subtitleBanner }}</h3>
      </div>
    </section>
  </div>
</template>

<script>
export default {
name: "Banner"
}
</script>

<style>
#banner {
  background: linear-gradient(rgba(0,0,0,0.6),#009688),url(../assets/images/barber-image-banner3.png);
  background-size: cover;
  background-position: 0% 0%;
  height: 250px;
  display: flex;
  justify-content: center;
}
.banner-text-container {
  /* background-color: pink; */
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
}
.banner-title {
  font-size: 38px;
  font-weight: 600;
  margin-top: -10px;
}
.banner-subtitle {
  font-size: 14px;
  font-weight: normal;
  margin-top: 0px;
}
@media screen and (max-width: 978px) {
  #banner {
    height: 175px;
  }
  .banner-title{
    font-size: 22px;
  }
  .banner-subtitle{
    font-size: 12.5px;
  }

}
</style>