<template>
  <!-- Dit is de homepagina! -->
  <div>

    <!-- Features -->
    <WhyChooseUs class="hidden"/>
    <!-- Service -->
    <ServiceSection class="hidden"/>
    <!-- Testimonial -->
    <Testimonial class="hidden"/>

</div>
</template>

<script>
import WhyChooseUs from "@/components/WhyChooseUs.vue";
import ServiceSection from "@/components/ServiceSection.vue";
import Testimonial from "@/components/Testimonial.vue";




export default {
name: "Home",
data(){
  return {
    // imgMenu: "menu.png"
  }
},
components: {
  WhyChooseUs,
  ServiceSection,
  Testimonial
},
methods: {

},
mounted(){
    this.$store.commit('updateBannerTitle', 'Aziz Style')
    this.$store.commit('updateBannerSubtitle', 'De beste kapper van Spijkenisse en omgeving.')

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting){
            entry.target.classList.add('show')
          } 
          /* to show animation multiple times */
          // else {
          //   entry.target.classList.remove('show')
          // }
        })
      })

      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach((el) => observer.observe(el))

      document.title = "Home - Aziz Style"
      document.querySelector('meta[name="description"]').setAttribute("content", "De beste kapper van Spijkenisse en omgeving.");
   
  }

}
</script>

<style scoped>

.hidden {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1.5s;
}

.show {
  opacity: 1;
  transform: translateY(0);
}



</style>
