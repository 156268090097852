<template>
  <div id="app">
    <NavBar/>
    <Banner/>
    <router-view />
  <Footer/>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "App",
  components: {
    NavBar,
    Banner,
    Footer
  },
};
</script>
<style>
/* global styling */
#app {
  /* margin: -8px; */
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.row {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
h1 {
    /* font-size: 36px;
    font-weight: 600; */
    font-weight: 600;
}
p {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding: 5px;
    margin: 0;
}

.title-text {
  text-align: center;
  padding-bottom: 70px;
}
.title-text p {
  margin: auto;
  font-size: 20px;
  color: #009688;
  font-weight: bold;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.title-text p::after {
  content: '';
  width: 50px;
  height: 35px;
  background: linear-gradient(#019587,#FFF);
  position: absolute;
  top: -20px;
  left: 0;
  z-index: -1;
  transform: rotate(10deg);
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.title-text h1 {
  font-size: 36px;
}

@media screen and (max-width: 978px) {
  .title-text {
    padding-bottom: 20px;
  }
  .title-text h1 {
    font-size: 20px;
  }
  .title-text p {
    font-size: 16px;
  }
}
</style>
