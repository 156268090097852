import { createStore } from 'vuex'

export default createStore({
  state: {
    titleBanner: 'Aziz Style',
    subtitleBanner: 'De beste kapper van Spijkenisse en omgeving.',
  },
  getters: {
  },
  mutations: {
    updateBannerTitle (state, title) {
      state.titleBanner = title
    },
    updateBannerSubtitle (state, subtitle) {
      state.subtitleBanner = subtitle
    },
  },
  actions: {
  },
  modules: {
  }
})
